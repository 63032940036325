import authHeader from './auth-header-config';
import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_APP_BASE_URL,
    headers: authHeader(),
    validateStatus: (status) => {
        if (status < 500 && status !== 401) return true;
    }
});

instance.defaults.baseURL = process.env.REACT_APP_APP_BASE_URL;
instance.defaults.headers.common['Authorization'] = process.env.REACT_APP_AUTH_TOKEN;
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.timeout = 0;

export default instance;