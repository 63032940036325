import React from 'react';
import { components } from 'react-select';

import { MdDelete } from "react-icons/md";
import { Box, Icon } from '@chakra-ui/react';

const Option = ({ children, ...props }) => {

    const { selectProps, data } = props;
    const { onDeleteOption, type } = selectProps;

    const boxStyle = {
        height: "40px",
        width: "40px",
        verticalAlign: "middle",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        float: "right",
    }
    
    return (
        <Box>
            {!!props.isFocused && data.__isNew__ !== true &&
                <Box style={boxStyle}>
                    <Icon id={data._id} className="delete" as={MdDelete} color='secondaryGray.500' h='18px' w='18px' onClick={() => onDeleteOption(data, type)} />
                </Box>
            }
            <components.Option {...props}>

                {children}
            </components.Option>
        </Box>
    );
};

export default Option;