/* eslint-disable */
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");

  const { routes } = props;

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {

      if (
        route.layout !== "/auth"
      ) {

        return (
          route?.childrens ?
            <Accordion
              key={index}
              allowMultiple
            >
              <AccordionItem
                border="0"
              >
                <AccordionButton
                  borderStyle="none"
                  padding="11px"
                >
                  {
                    route.icon && <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me='18px'>
                      {route.icon}
                    </Box>
                  }

                  <Text
                    me='auto'
                    color={textColor}
                    fontWeight={"normal"}>
                    {route.name}
                  </Text>
                  <AccordionIcon />
                </AccordionButton>
                {
                  route?.childrens.map((child, index) => {
                    return <AccordionPanel key={index} pb={4}>
                      <NavLink key={index} to={child.layout + child.path}>
                        <Box key={index} className="nav">
                          <HStack
                            className="nav-content"
                            spacing={"26px"}
                            py='5px'
                            ps='10px'>
                            <Flex w='100%' alignItems='center' justifyContent='center' className="nav-item">

                              {
                                child.icon && <Box
                                  color={textColor}
                                  me='18px'>
                                  {child.icon}
                                </Box>
                              }

                              <Text
                                me='auto'
                                color={
                                  activeRoute(child.path.toLowerCase())
                                    ? activeColor
                                    : textColor
                                }
                                fontWeight={
                                  activeRoute(child.path.toLowerCase())
                                    ? "bold"
                                    : "normal"
                                }>
                                {child.name}
                              </Text>
                            </Flex>
                          </HStack>
                        </Box>
                      </NavLink>
                    </AccordionPanel>
                  })
                }
              </AccordionItem>
            </Accordion>
            :
            <NavLink key={index} to={route.layout + route.path}>
              <Box key={index} className="nav">
                <HStack
                  className="nav-content"
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Flex w='100%' alignItems='center' justifyContent='center' className="nav-item">

                    {
                      route.icon && <Box
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeIcon
                            : textColor
                        }
                        me='18px'>
                        {route.icon}
                      </Box>
                    }

                    <Text
                      me='auto'
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }>
                      {route.name}
                    </Text>
                  </Flex>
                </HStack>
              </Box>
            </NavLink>
        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
