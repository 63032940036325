import React from "react";

// Chakra imports
import { Box } from "@chakra-ui/react";
import NovoConteudo from "views/admin/modelos/inserirModelos/components/NovoConteudo";

export default function Modelos(props) {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <NovoConteudo
                props={props}
                title="Inserir Laudo"
                value="Preencha as informações do laudo"
            />
        </Box>
    );
}