import instance from "config/axios-config";

const axios = instance;

const upload = async (file) => {
    let formData = new FormData();

    formData.append("file", file);

    return axios.post("/upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });
}

// eslint-disable-next-line
export {
    upload
}