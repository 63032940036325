import React from "react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Text,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    Grid,
    Flex
} from '@chakra-ui/react';

import { Form, Formik } from "formik";
import SwitchField from "components/fields/SwitchField";
import { ToastContainer } from "react-toastify";

export default function ModalCategory(props) {
    const { handleEditCategory, handleDeleteCategory, type } = props;

    // Chakra Color Mode
    const textColorSecondary = "gray.400";
    const textColor = useColorModeValue("navy.700", "white");

    const fontSize = "sm";

    const { config } = props.data;

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            {type === "edit" &&
                <ModalContent>
                    <ModalHeader>Editar</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody paddingBottom="5">
                        <Formik
                            initialValues={
                                {
                                    onClose: props.onClose,
                                    name: props.data.name,
                                    id: props.data._id,
                                    config: {
                                        numeroAutos: config.numeroAutos,
                                        banco: config.banco,
                                        tipoOperacao: config.tipoOperacao,
                                        depositoJudicialGarantia: config.depositoJudicialGarantia,
                                        compensacaoCLSaldoDevedor: config.compensacaoCLSaldoDevedor,
                                        levantamento: config.levantamento,
                                        atualizacaoSelic: config.atualizacaoSelic,
                                        setenca: config.setenca,
                                        contrato: config.contrato,
                                    }

                                }
                            }
                            onSubmit={async (values, { setSubmitting }) => {
                                await handleEditCategory(values, { setSubmitting });
                            }}
                        >
                            {(props) => (
                                <Form onSubmit={props.handleSubmit}>
                                    <FormControl>
                                        <Grid mb="20px">
                                            <Box>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'>
                                                    Título do modelo<Text color='orange'>*</Text>
                                                </FormLabel>
                                                <Input
                                                    isRequired
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    type='text'
                                                    placeholder='Ex: Metodologia'
                                                    mb='10px'
                                                    fontWeight='500'
                                                    size='lg'
                                                    name="name"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props.values.name}
                                                />
                                            </Box>

                                            <Box mt="20px">
                                                <Text
                                                    color={textColorSecondary}
                                                    fontWeight='bold'
                                                    fontSize='md'
                                                    mt='5px'
                                                    mb='4px'
                                                >
                                                    Configurações
                                                </Text>

                                                <Text color={textColorSecondary} fontWeight='200' fontSize='sm'>
                                                    Edite os campos a serem mostrados para o modelo
                                                </Text>

                                                <Flex mt="10px" flexDirection="column">
                                                    <SwitchField
                                                        isChecked={props.values.config.numeroAutos}
                                                        reversed={true}
                                                        fontSize={fontSize}
                                                        id="0"
                                                        label="Número de Autos"
                                                        onChangeCheck={(e) =>
                                                            props.values.config.numeroAutos = e.target.checked
                                                        }
                                                    />

                                                    <SwitchField
                                                        isChecked={props.values.config.banco}
                                                        reversed={true}
                                                        fontSize={fontSize}
                                                        id="1"
                                                        label="Bancos"
                                                        onChangeCheck={(e) => props.values.config.banco = e.target.checked}
                                                    />

                                                    <SwitchField
                                                        isChecked={props.values.config.tipoOperacao}
                                                        reversed={true}
                                                        fontSize={fontSize}

                                                        id="2"
                                                        label="Tipo de operação"
                                                        onChangeCheck={(e) => props.values.config.tipoOperacao = e.target.checked}
                                                    />

                                                    <SwitchField
                                                        isChecked={props.values.config.depositoJudicialGarantia}
                                                        reversed={true}
                                                        fontSize={fontSize}

                                                        id="3"
                                                        label="Depósito Judicial/Garantia"
                                                        onChangeCheck={(e) => props.values.config.depositoJudicialGarantia = e.target.checked}
                                                    />

                                                    <SwitchField
                                                        isChecked={props.values.config.compensacaoCLSaldoDevedor}
                                                        reversed={true}
                                                        fontSize={fontSize}

                                                        id="4"
                                                        label="Comp. CL/Saldo Devedor"
                                                        onChangeCheck={(e) => props.values.config.compensacaoCLSaldoDevedor = e.target.checked}
                                                    />

                                                    <SwitchField
                                                        isChecked={props.values.config.levantamento}
                                                        reversed={true}
                                                        fontSize={fontSize}

                                                        id="5"
                                                        label="Levantamento"
                                                        onChangeCheck={(e) => props.values.config.levantamento = e.target.checked}
                                                    />

                                                    <SwitchField
                                                        isChecked={props.values.config.atualizacaoSelic}
                                                        reversed={true}
                                                        fontSize={fontSize}

                                                        id="6"
                                                        label="Atualização pela Selic"
                                                        onChangeCheck={(e) => props.values.config.atualizacaoSelic = e.target.checked}
                                                    />

                                                    <SwitchField
                                                        isChecked={props.values.config.setenca}
                                                        reversed={true}
                                                        fontSize={fontSize}
                                                        id="7"
                                                        label="Sentença"
                                                        onChangeCheck={(e) => props.values.config.setenca = e.target.checked}
                                                    />

                                                    <SwitchField
                                                        isChecked={props.values.config.contrato}
                                                        reversed={true}
                                                        fontSize={fontSize}
                                                        id="8"
                                                        label="Contrato"
                                                        onChangeCheck={(e) => props.values.config.contrato = e.target.checked}
                                                    />
                                                </Flex>
                                            </Box>
                                        </Grid>
                                    </FormControl>


                                    <ModalFooter p="0">
                                        <Button colorScheme='green' mr={3} type="submit">
                                            Salvar Edição
                                        </Button>
                                        <Button colorScheme='red' onClick={props.initialValues.onClose}>
                                            Cancelar
                                        </Button>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            }

            {
                type === "delete" &&
                <ModalContent>
                    <ModalHeader>Remover Modelo</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody paddingBottom="5">
                        <Formik
                            initialValues={
                                {
                                    onClose: props.onClose,
                                    name: props.data.name,
                                    id: props.data._id,
                                }
                            }
                            onSubmit={async (values, { setSubmitting }) => {
                                await handleDeleteCategory(values, { setSubmitting });
                            }}
                        >
                            {(props) => (
                                <Form onSubmit={props.handleSubmit}>
                                    <FormControl>
                                        <Grid mb="20px">
                                            <Box>
                                                <Text
                                                    color={textColorSecondary}
                                                    fontWeight='bold'
                                                    fontSize='md'
                                                    mt='5px'
                                                    mb='4px'
                                                >
                                                    Tem certeza que deseja remover {(props.values.name)}?
                                                </Text>

                                                <Text color={textColorSecondary} fontWeight='200' fontSize='sm'>
                                                    Obs: Atenção esta opção não pode ser desfeita.
                                                </Text>
                                            </Box>
                                        </Grid>
                                    </FormControl>


                                    <ModalFooter p="0">
                                        <Button colorScheme='green' mr={3} type="submit">
                                            Sim
                                        </Button>
                                        <Button colorScheme='red' onClick={props.initialValues.onClose}>
                                            Não
                                        </Button>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            }
            <ToastContainer />
        </Modal>
    )
}