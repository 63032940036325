import React, { useState } from "react";

// Chakra imports
import {
  Badge,
  Box,
  Flex,
  Heading,
  Tag,
  Text,
} from "@chakra-ui/react";

// Custom components
import Menu from "components/menu/MainMenu";
import Card from "components/card/Card.js";
import ModalConteudo from "views/admin/modelos/consultarModelos/components/ModalConteudo";

export default function LaudoCard(props) {

  const { _id, categoria, banco, tipoOperacao, updated_at } = props;

  const headStyles = {
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    position: "relative",
    overflow: "hidden",
  }

  const [openModalConteudo, setOpenModalConteudo] = useState(false);

  function handleOpenModal() {
    setOpenModalConteudo(!openModalConteudo);
  }

  return (
    <>
      <Card key={_id} p='20px' className="cardStyle" onDoubleClick={handleOpenModal}>
        <Flex alignItems='center' w='100%' mb='10px'>
          <Badge variant='outline' size="xs" fontSize="10px" p="5px">
            {categoria.name.toUpperCase()}
          </Badge>
          <Menu ms='auto' />
        </Flex>

        <Box className="cardHeader">
          <Flex direction='column'>
            <Heading size='sm' style={headStyles} title={props.title} >{props.title.toUpperCase()}</Heading>
          </Flex>
        </Box>

        <Box mt="10px" className="detalhes">
          <Flex direction='column'>
            <Text
              color='secondaryGray.600'
              fontSize={{
                base: "12px",
              }}
              fontWeight='400'
              me='0'>
              {banco.name !== "" && Object.keys(banco).length > 0 ? `Banco: ${banco.name}` : ""}
            </Text>

            <Text
              color='secondaryGray.600'
              fontSize={{
                base: "12px",
              }}
              fontWeight='400'
              me='0'>
              {tipoOperacao.length > 0 &&
                <>
                  Tipo Op.: &nbsp;
                  {
                    tipoOperacao.map((tipoOp, index) => {
                      return <span key={index}>{tipoOp.title}; </span>
                    })
                  }
                </>
              }
            </Text>
          </Flex>
        </Box>

        <Box className="cardBody tags" mt="15px">
          <Flex gap="1" flexDirection="row" flexWrap="wrap" alignItems="flex-end">
            <Tag size="sm" variant='solid' colorScheme='teal'>Ajg</Tag>
            <Tag size="sm" variant='solid' colorScheme='teal'>Contrato</Tag>
            <Tag size="sm" variant='solid' colorScheme='teal'>Setença</Tag>
            <Tag size="sm" variant='solid' colorScheme='teal'>Levantamento</Tag>
            <Tag size="sm" variant='solid' colorScheme='teal'>CL</Tag>
            <Tag size="sm" variant='solid' colorScheme='teal'>DJG</Tag>
          </Flex>
        </Box>

        <Box className="cardFooter" mt='25px'>
          <Flex>
            <Text
              color='secondaryGray.600'
              fontSize={{
                base: "12px",
              }}
              fontWeight='400'
              me='0'>
              {new Date(updated_at).toLocaleDateString('pt-BR')} - Por: {props.perito.name}
            </Text>
          </Flex>
        </Box>
      </Card >
      {
        openModalConteudo &&
        <ModalConteudo
          data={props}
          isOpen={openModalConteudo}
          setOpenModalConteudo={setOpenModalConteudo}
        />
      }
    </>
  );
}
