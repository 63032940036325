import { ApiContext } from "contexts/ApiContext";

import {
    createCategory,
    readCategory,
    readCategories,
    updateCategory,
    deleteCategory
} from "services/category-service";

import {
    createPerito,
    readPerito,
    deletePerito
} from "services/perito-service";

import {
    createVara,
    readVara,
    deleteVara
} from "services/vara-service";

import {
    readBancos
} from "services/bancos-service";

import {
    createTipoOperacao,
    readTipoOperacao,
    deleteTipoOperacao
} from "services/tipo-operacao-service";

import {
    createContent,
    getTextContent,
    getContentModel
} from "services/content-service";

import { upload } from "services/upload-service";

export const ApiProvider = ({ children }) => {
    return (
        <ApiContext.Provider value={{
            createCategory,
            readCategory,
            readCategories,
            updateCategory,
            deleteCategory,

            createPerito,
            readPerito,
            deletePerito,

            createVara,
            readVara,
            deleteVara,

            readBancos,

            createTipoOperacao,
            readTipoOperacao,
            deleteTipoOperacao,

            upload,

            createContent,
            getTextContent,
            getContentModel
        }}>
            {children}
        </ApiContext.Provider>
    );
}