import React, { useContext, useRef, useState } from "react";
import { ApiContext } from "contexts/ApiContext";

import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Flex, FormLabel, Heading, Input, Spinner, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { ToastContainer, toast } from 'react-toastify';

import { Editor } from '@tinymce/tinymce-react';

export default function EditorConteudo(props) {

    const { upload } = useContext(ApiContext);
    const { formikProps, setLaudoTitle } = props;

    const editorRef = useRef(null);
    const textColor = useColorModeValue("secondaryGray.900", "secondaryGray.900");

    const [isDocumentLoading, setIsDocumentLoading] = useState(false);
    const [dragActive, setDragActive] = React.useState(false);
    const [document, setDocument] = React.useState({});

    async function handleUploadFile(event) {
        setIsDocumentLoading(true);
        setDragActive(true);
        setDocument(event.target.files[0]);

        const title = event.target.files[0].name.replace(".docx", "").toUpperCase();
        setLaudoTitle(title);

        formikProps.values.title = title;
        
        const uploadDocument = await upload(event.target.files[0]);

        if (uploadDocument.status === 200) {
            if (editorRef.current) {
                editorRef.current.setContent(uploadDocument.data.data);
                formikProps.values.metadata = uploadDocument.data.metadata;
            }

            toast.success("Documento carregado com sucesso!");
        } else {
            toast.error("Ops, ocorreu um erro ao fazer upload do documento.");
        }

        setIsDocumentLoading(false);
    }

    return (
        <>
            <FormLabel
                as='legend'
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Descrição<Text color='orange'>*</Text>
            </FormLabel>


            <Flex
                justifyContent="center"
                alignItems="center"
                borderColor="gray.300"
                borderStyle="dashed"
                borderWidth="2px"
                rounded="md"
                shadow="sm"
                role="group"
                transition="all 150ms ease-in-out"
                _hover={{
                    shadow: "md"
                }}
                initial="rest"
                animate="rest"
                height="100px"
                my="15"
            >
                {
                    isDocumentLoading ? <div className="loading">
                        <Flex justifyContent="center">
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='gray.500'
                                size='xl'
                            />
                        </Flex>
                    </div> :
                        <Box position="relative" height="100%" width="100%">
                            <Box
                                position="absolute"
                                top="0"
                                left="0"
                                height="100%"
                                width="100%"
                                display="flex"
                                flexDirection="column"
                            >
                                <Stack
                                    height="100%"
                                    width="100%"
                                    display="flex"
                                    alignItems="center"
                                    justify="center"
                                    spacing="4"
                                >
                                    <Stack p="8" textAlign="center" spacing="1">
                                        <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                                            Arraste o documento aqui
                                        </Heading>
                                        <Text fontWeight="light" color="gray.700">ou clique para fazer upload</Text>
                                    </Stack>
                                </Stack>
                            </Box>
                            <Input
                                type="file"
                                height="100%"
                                width="100%"
                                position="absolute"
                                top="0"
                                left="0"
                                opacity="0"
                                aria-hidden="true"
                                accept=".doc,.docx"
                                onChange={handleUploadFile}
                            />
                        </Box>
                }

            </Flex>

            {
                dragActive &&
                <Box bg="#f1f1f1" my="10px" p="5px" borderRadius="5px">
                    <Text fontWeight="light">Documento: {document.name}</Text>
                </Box>
            }

            {   
                isDocumentLoading &&
                <Box mb="15px" mt="15px">
                    <Alert status='warning'>
                        <AlertIcon />
                        <AlertTitle>Atenção documento sendo carregado:</AlertTitle>
                        <AlertDescription>Por favor aguarde o upload do documento.</AlertDescription>
                    </Alert>
                </Box>
            }

            <Editor
                disabled={isDocumentLoading}
                apiKey='0l8z94y9latry5uii6ahiid49ckgpknpxdhwilcxz7b2szpv'
                onInit={(evt, editor) => editorRef.current = editor}
                init={{
                    branding: false,
                    height: 600,
                    menubar: true,
                    plugins: [
                        // 'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        // 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                onChange={() => {
                    if (editorRef.current) {
                        formikProps.values.content = editorRef.current.getContent();
                    }
                }}
            />

            <ToastContainer />
        </>
    )
};