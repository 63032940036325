import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";

import { AuthProvider } from "provider/AuthProvider";
import { AuthManager } from "provider/AuthManager";
import { ApiProvider } from "provider/ApiProvider";

import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <AuthProvider>
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <ThemeEditorProvider>
          <HashRouter>
            <Switch>
              <Route path={`/auth`} component={AuthLayout} />
              <Route path={`/admin`} component={() =>
                <AuthManager>
                  <ApiProvider>
                    <AdminLayout />
                  </ApiProvider>
                </AuthManager>} />
              <Redirect from='/' to='/admin' />
            </Switch>
          </HashRouter>
        </ThemeEditorProvider>
      </React.StrictMode>
    </ChakraProvider>
  </AuthProvider>,
  document.getElementById("root")
);
