import instance from "config/axios-config";

const axios = instance;

const createContent = async (data) => {
    return await axios.post("/contentmodel", {
        data
    }).then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });

}

const getTextContent = async (data) => {
    return await axios.get(`/getcontent/${data}`).then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });
}

const getContentModel = async () => {
    return await axios.get(`/contentmodel`).then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });
}

// eslint-disable-next-line
export {
    createContent,
    getTextContent,
    getContentModel
}