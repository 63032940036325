import React from 'react';
import { Box, Divider, FormLabel, Input, HStack, Flex, useColorModeValue, Button } from '@chakra-ui/react';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';

export default function ListRecebimentos({ props, index, handleRemoveRecebimento }) {

    const { formikProps } = props;

    const selectSecondaryColor = useColorModeValue("secondaryGray.100");
    const textColor = useColorModeValue("secondaryGray.900", "secondaryGray.900");
    const selectStyle = {
        control: (baseStyles, _) => ({
            ...baseStyles,
            borderRadius: "16px",
            borderColor: "#878cbd4d",
            color: "#878cbd4d",
            height: "2.5rem"
        }),
        option: (baseStyles, _) => ({
            ...baseStyles,
            color: "#333333",
        }),
    }

    const selectOptions = [
        { value: "instauracao", label: "50% (Instauração)" },
        { value: "final", label: "50% (Final)" },
        { value: "total", label: "100% (Completo)" },
        { value: "parcial", label: "Parcial (Outros)" }
    ]

    return (
        <>
            <HStack spacing='24px' mb="15px" mt="15px" justifyContent="center" alignItems="center">
                <Box w='33%' h="70px">
                    <FormLabel
                        as='legend'
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Data de recebimento
                    </FormLabel>
                    <Input
                        variant='auth'
                        placeholder="Select Date and Time"
                        size="md"
                        type="date"
                        name="dataRecebimento"
                        onChange={(e) => {
                            formikProps.values.recebimento.recebidos[index].dataRecebimento = e.target.value;
                        }}
                    />
                </Box>
                <Box w='33%' h="70px">
                    <FormLabel
                        as='legend'
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Valor recebido
                    </FormLabel>
                    <Input
                        variant='auth'
                        size="md"
                        as={NumericFormat}
                        decimalSeparator=","
                        thousandSeparator="."
                        prefix={'R$ '}
                        placeholder='R$ 0,00'
                        name="valorRecebido"
                        onValueChange={(values) => {
                            formikProps.values.recebimento.recebidos[index].valorRecebido = values.floatValue !== undefined ? values.floatValue : 0;
                        }}
                    />
                </Box>
                <Box w='33%' h="70px">
                    <FormLabel
                        as='legend'
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Tipo do recebimento
                    </FormLabel>
                    <Select
                        isRequired
                        isClearable
                        isSearchable={false}
                        styles={selectStyle}
                        name="tipoRecebimentos"
                        placeholder='Selecione'
                        options={selectOptions}
                        onChange={(e) => {
                            formikProps.values.recebimento.recebidos[index].tipoRecebimento = e !== null ? e.value : '';
                        }}
                    />
                </Box>
                <Flex w='5%' h="70px" justifyContent="center" alignItems="center">
                    <Button h="40px" mt="25px" bg="red" color="#fff" onClick={() => handleRemoveRecebimento(index)}>Remover</Button>
                </Flex>
            </HStack>

            <Divider />
        </>
    );
};