import React from "react";

// Chakra imports
import { Box } from "@chakra-ui/react";
import CategoryConfig from "views/admin/category/components/CategoryConfig";

export default function Category(props) {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <CategoryConfig
                props={props}
                title="Novo modelo"
                value="Adicione e configure novos modelos"
            />
        </Box>
    );
}