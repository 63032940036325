import React, { Fragment } from "react";

import {
    Box,
    Divider,
    Flex,
    FormLabel,
    Input,
    Radio,
    RadioGroup,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue
} from "@chakra-ui/react";

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { textColorSecondary } from "../styles";

import Option from "./ComboBox";

export default function ConfigContent(props) {

    const {
        formikProps,
        configContent,
        bancoOptions,
        isLoading,
        tipoOperacaoOptions,
        handleCreateOption,
        handleDeleteOption,
    } = props;

    const textColor = useColorModeValue("secondaryGray.900", "secondaryGray.900");

    const selectStyle = {
        control: (baseStyles, _) => ({
            ...baseStyles,
            borderRadius: "16px",
            borderColor: "#878cbd4d",
            color: "#878cbd4d",
            height: "2.5rem"
        }),
        option: (baseStyles, _) => ({
            ...baseStyles,
            color: "#333333",
        }),
    }

    return (
        <>
            {
                Object.keys(configContent).length > 0 &&
                <Box>
                    <Stack direction='row' h='50px' p={0} alignItems="center">
                        <Text color={textColorSecondary} fontWeight='200' fontSize='md'>
                            Consid. finais
                        </Text>

                        <Divider orientation='horizontal' w="70%" />
                    </Stack>
                    <SimpleGrid columns='2' gap='20px'>
                        {
                            configContent.config.map((config, index) => {
                                if (config.active) {

                                    if (config.type === "input") {
                                        return <Flex key={index} flexDirection="column">
                                            <FormLabel
                                                as='legend'
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color={textColor}
                                                mb='8px'>
                                                {config.name} <Text color='orange'>*</Text>
                                            </FormLabel>
                                            <Input
                                                isRequired
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                type='text'
                                                placeholder='Ex: 123'
                                                mb='10px'
                                                fontWeight='500'
                                                size='md'
                                                name="numeroAutos"
                                                onChange={(e) => {
                                                    formikProps.values.numeroAutos = e.target.value;
                                                }}
                                            />
                                        </Flex>
                                    }

                                    if (config.type === "select") {
                                        return <Flex key={index} flexDirection="column">
                                            <FormLabel
                                                as='legend'
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color={textColor}
                                                mb='8px'>
                                                {config.name} <Text color='orange'>*</Text>
                                            </FormLabel>
                                            <Select
                                                isRequired
                                                isClearable
                                                styles={selectStyle}
                                                name="bancos"
                                                placeholder='Selecione'
                                                options={bancoOptions}
                                                onChange={(e) => {
                                                    if (e !== null) {
                                                        formikProps.values.banco.name = e.name;
                                                        formikProps.values.banco._id = e._id;
                                                    }
                                                }}
                                            />
                                        </Flex>
                                    }
                                }
                                return <Fragment key={index} />
                            })
                        }
                    </SimpleGrid>
                    <SimpleGrid columns='1' gap='20px' mb="15px">
                        {
                            configContent.config.map((cbx, index) => {
                                if (cbx.active) {
                                    if (cbx.type === "combobox") {
                                        return <Flex key={index} flexDirection="column">
                                            <FormLabel
                                                as='legend'
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color={textColor}
                                                mb='8px'>
                                                {cbx.name} <Text color='orange'>*</Text>
                                            </FormLabel>
                                            <CreatableSelect
                                                components={{ Option }}
                                                isMulti
                                                isRequired
                                                isClearable
                                                isDisabled={isLoading.peritos}
                                                isLoading={isLoading.peritos}
                                                styles={selectStyle}
                                                name="perito"
                                                placeholder='Selecione...'
                                                formatCreateLabel={userInput => `Adicionar ${userInput}`}
                                                options={tipoOperacaoOptions}
                                                type="tipoOperacao"
                                                onCreateOption={(value) => {
                                                    handleCreateOption(value, "tipoOperacao")
                                                }}
                                                onDeleteOption={handleDeleteOption}
                                                onChange={(e) => {
                                                    if (e.length > 0) {
                                                        const treathOption = [];

                                                        e.forEach(option => {
                                                            treathOption.push({
                                                                title: option.title,
                                                                _id: option._id,
                                                            })
                                                        });

                                                        formikProps.values.tipoOperacao = treathOption;
                                                    } else {
                                                        formikProps.values.tipoOperacao = [];
                                                    }
                                                }}
                                            />
                                        </Flex>
                                    }
                                }
                                return <Fragment key={index} />
                            })
                        }
                    </SimpleGrid>

                    <SimpleGrid columns='3' gap='20px'>
                        {
                            configContent.config.map((cb, index) => {
                                if (cb.active) {
                                    if (cb.type === "boolean") {
                                        return <Flex key={index} flexDirection="column">
                                            <FormLabel
                                                as='legend'
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color={textColor}
                                                mb='8px'>
                                                {cb.name} <Text color='orange'>*</Text>
                                            </FormLabel>
                                            <RadioGroup
                                                name={cb.value}
                                                onChange={(e) => {
                                                    const prop = cb.value;
                                                    formikProps.values[prop] = e === 'sim' ? true : false;
                                                }}
                                            >
                                                <Stack spacing={4} direction='row'>
                                                    <Radio value="sim">Sim</Radio>
                                                    <Radio value="nao">Não</Radio>
                                                </Stack>
                                            </RadioGroup>
                                        </Flex>
                                    }
                                }
                                return <Fragment key={index} />
                            })
                        }
                    </SimpleGrid>
                </Box>
            }
        </>
    )

};