import React, { useState, useCallback, useEffect, useContext } from "react";
import {
    Box,
    Button,
    Divider,
    Flex,
    FormLabel,
    Input,
    Spinner,
    Stack,
    Tag,
    Text,
    Tabs,
    TabPanels,
    TabPanel,
    useColorModeValue
} from "@chakra-ui/react";

import { textColorSecondary } from "../styles";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import { ApiContext } from "contexts/ApiContext";
import { treathShowConfigAttributes } from "utils/treathConfig";

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

// Custom components
import Card from "components/card/Card";
import Option from "./ComboBox";
import EditorConteudo from "./EditorConteudo";
import PeritoInfos from "./PeritoInfos";
import Honorarios from "./Honorarios";
import Recebimentos from "./Recebimentos";
import ConfigContent from "./ConfigContent";

export default function NovoConteudo(props) {

    const {
        readCategories,
        createPerito,
        readPerito,
        deletePerito,
        createVara,
        readVara,
        deleteVara,
        readBancos,
        createTipoOperacao,
        readTipoOperacao,
        deleteTipoOperacao,
        createContent,
    } = useContext(ApiContext);

    // Chakra Color Mode
    const bg = useColorModeValue("navy.900", "navy.700");
    const contentbg = useColorModeValue("white", "white");
    const cardShadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "unset"
    );
    const tabStyle = {
        backgroundColor: contentbg,
        borderRadius: "10px",
        padding: "30px",
    }

    const selectStyle = {
        control: (baseStyles, _) => ({
            ...baseStyles,
            borderRadius: "16px",
            borderColor: "#878cbd4d",
            color: "#878cbd4d",
            height: "2.5rem"
        }),
        option: (baseStyles, _) => ({
            ...baseStyles,
            color: "#333333",
        }),
    }

    const textColor = useColorModeValue("secondaryGray.900", "secondaryGray.900");

    const { title, value } = props;

    const [tabIndex, setTabIndex] = useState(0);
    const [nextTabIndex, setNextTabIndex] = useState(false);
    const [loading, setLoading] = useState(true);
    const [submit, setSubmit] = useState(false);
    const [models, setModels] = useState([]);
    const [configContent, setConfigContent] = useState({});
    const [laudoTitle, setLaudoTitle] = useState("");
    const [ajg, setAjg] = useState(false);
    const [recebimento, setRecebimento] = useState(false);
    const [recebimentosComponent, setComponenteRecebimento] = useState([]);
    const [peritosOptions, setPeritosOptions] = useState([]);
    const [varaOptions, setVaraOptions] = useState([]);
    const [bancoOptions, setBancoOptions] = useState([]);
    const [tipoOperacaoOptions, setTipoOperacaoOptions] = useState([]);
    const [ajgPercentValues, setajgPercentValues] = useState({
        honorario: null,
        percentage: null,
        result: null,
    });
    const [isLoading, setIsLoading] = useState({
        peritos: false,
        vara: false,
        tipoOperacao: false,
    });

    const booleanOptions = [
        {
            label: "Sim",
            value: true,
        },
        {
            label: "Não",
            value: false,
        },
    ];

    function handleSetModelConfigContent(value) {
        const selectedModel = value;

        if (selectedModel !== null) {
            const selectConfig = models.filter(m => m._id === selectedModel._id);

            const treathSelectedConfig = {
                ...selectConfig[0],
                config: treathShowConfigAttributes(selectConfig[0].config),
            }
            setConfigContent(treathSelectedConfig);
        } else {
            setConfigContent({})
        }
    }

    function handleTabsChange(event) {
        setTabIndex(parseInt(event.target.value));
        setNextTabIndex(true);
    }

    function handleCalcPercentagrAJG(value) {
        setajgPercentValues({ ...ajgPercentValues, percentage: value });

        if (ajgPercentValues.honorario !== null && !ajgPercentValues.percentage !== null) {
            const result = (ajgPercentValues.honorario * value) / 100;
            setajgPercentValues({ ...ajgPercentValues, result: result });
        }
    }

    function handleAddRecebimento(props) {
        setComponenteRecebimento([...recebimentosComponent, props]);
    }

    function handleRemoveRecebimento(index) {
        if (index >= 0 && index < recebimentosComponent.length) {
            const newRecebimentosComponent = [...recebimentosComponent]; // Cria uma cópia do array original
            newRecebimentosComponent.splice(index, 1); // Remove o objeto do novo array
            setComponenteRecebimento(newRecebimentosComponent); // Atualiza o estado do componente com o novo array
        }
    }

    async function handleCreateOption(inputValue, type) {
        if (type === "perito") {

            setIsLoading({ peritos: true });

            const option = {
                name: inputValue
            }

            const createPeritoOption = await createPerito(option);

            if (createPeritoOption.status === 201) {

                const treathPerito = {
                    ...createPeritoOption.data.data,
                    value: createPeritoOption.data.data._id,
                    label: createPeritoOption.data.data.name,
                }

                setPeritosOptions([...peritosOptions, treathPerito]);
                setIsLoading({ peritos: false });
            }

        }

        if (type === "vara") {

            setIsLoading({ vara: true });

            const optionVara = {
                name: inputValue
            }

            const createVaraOption = await createVara(optionVara);

            if (createVaraOption.status === 201) {

                const treathVara = {
                    ...createVaraOption.data.data,
                    value: createVaraOption.data.data._id,
                    label: createVaraOption.data.data.name,
                }

                setVaraOptions([...varaOptions, treathVara]);
            }

            setIsLoading({
                peritos: false,
                vara: false,
            });
        }

        if (type === "tipoOperacao") {

            setIsLoading({ tipoOperacao: true });

            const optionTipoOperacao = {
                title: inputValue
            }

            const createTipoOperacaoOption = await createTipoOperacao(optionTipoOperacao);

            if (createTipoOperacaoOption.status === 201) {

                const treathTipoOperacao = {
                    ...createTipoOperacaoOption.data.data,
                    value: createTipoOperacaoOption.data.data._id,
                    label: createTipoOperacaoOption.data.data.title,
                }

                setTipoOperacaoOptions([...tipoOperacaoOptions, treathTipoOperacao]);
            }

            setIsLoading({
                peritos: false,
                vara: false,
                tipoOperacao: false,
            });
        }
    }

    async function handleDeleteOption(value, type) {
        if (type === "perito") {
            setIsLoading({ peritos: true });
            const deleteOption = await deletePerito(value._id);

            if (deleteOption.status === 200) {
                setPeritosOptions(peritosOptions.filter(perito => perito._id !== value._id));
            }
        }

        if (type === "vara") {
            setIsLoading({ vara: true });
            const deleteVaraOption = await deleteVara(value._id);

            if (deleteVaraOption.status === 200) {
                setVaraOptions(varaOptions.filter(vara => vara._id !== value._id));
            }
        }

        if (type === "tipoOperacao") {
            setIsLoading({ tipoOperacao: true });
            const deleteTipoOperacaoOption = await deleteTipoOperacao(value._id);

            if (deleteTipoOperacaoOption.status === 200) {
                setTipoOperacaoOptions(tipoOperacaoOptions.filter(vara => vara._id !== value._id));
            }
        }

        setIsLoading({
            peritos: false,
            vara: false,
            tipoOperacao: false,
        });
    }

    async function handleSubmitContent(value) {
        setSubmit(true);

        const content = await createContent(value);

        if (content.status === 201) {
            toast.success("Conteúdo cadastrado com sucesso! Recarregando a página...");
            setTimeout(() => {
                window.location.reload(true);
            }, 5000)
        }

        if (content.status === 400 || content.status === 401 || content.status === 413) {
            toast.error("Ops, ocorreu um erro ao salvar o conteúdo.");
        }

        if (content.status === 202) {
            toast.warn("Ops, modelo já existente.");
        }

        setSubmit(false);
    }

    const getModels = useCallback(async () => {
        const requestModels = await readCategories();

        if (requestModels.status === 400 || requestModels.status === 401) {
            setLoading(false);
            toast.error("Ops, ocorreu um erro ao carregar os modelos.")
            return false
        }

        setLoading(false);
        setModels(requestModels.data.data.map(dt => {
            return {
                ...dt,
                value: dt._id,
                label: dt.name,
                created_at: format(parseISO(dt.created_at), "dd/MM/yyyy"), timeZone: 'America/Sao_Paulo',
            }
        }));
    }, [readCategories]);

    const populateSelectOptions = useCallback(async () => {
        const peritoOption = await readPerito();
        const varaOption = await readVara();
        const bancoOption = await readBancos();
        const tipoOperacaoOption = await readTipoOperacao();

        if (peritoOption.data.data.length > 0) {
            setPeritosOptions(
                peritoOption.data.data.map(p => {
                    return {
                        ...p,
                        value: p._id,
                        label: p.name,
                    }
                })
            );
        }

        if (varaOption.data.data.length > 0) {
            setVaraOptions(
                varaOption.data.data.map(p => {
                    return {
                        ...p,
                        value: p._id,
                        label: p.name,
                    }
                })
            );
        }

        if (bancoOption.data.data.length > 0) {
            setBancoOptions(
                bancoOption.data.data.map(p => {
                    return {
                        ...p,
                        value: p._id,
                        label: p.name,
                    }
                })
            );
        }

        if (tipoOperacaoOption.data.data.length > 0) {
            setTipoOperacaoOptions(
                tipoOperacaoOption.data.data.map(p => {
                    return {
                        ...p,
                        value: p._id,
                        label: p.title,
                    }
                })
            );
        }

    }, [readPerito, readVara, readBancos, readTipoOperacao]);

    useEffect(() => {
        getModels();
        populateSelectOptions();
    }, [getModels, populateSelectOptions]);

    return (
        <Box>
            {
                loading ? <div className="loading">
                    <Flex justifyContent="center">
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='gray.500'
                            size='xl'
                        />
                    </Flex>
                </div>
                    :
                    <Box>
                        <Card bg={bg} zIndex="-1">
                            <Box mb="100px">
                                <Text color="#fff" fontWeight='bold' fontSize='2xl' textAlign='center' mt='10px' mb='4px'>
                                    {title}
                                </Text>

                                <Text color={textColorSecondary} fontWeight='200' fontSize='sm' textAlign='center'>
                                    {value}
                                </Text>

                                {
                                    configContent !== undefined && Object.keys(configContent).length > 0 &&
                                    <Text color={textColorSecondary} fontWeight='200' fontSize='sm' textAlign='center' mt="30px">
                                        <Tag bg="orange">MODELO: {configContent.name.toUpperCase()}</Tag>
                                    </Text>
                                }
                            </Box>
                        </Card>

                        <Box className="container" mt="-90px" mr="70px" ml="70px">
                            <Formik
                                initialValues={{
                                    title: '',
                                    content: '',
                                    metadata: [],
                                    perito: {},
                                    dataEntrega: '',
                                    ajg: {},
                                    honorario: 0,
                                    recebimento: {
                                        recebidos: [],
                                    },
                                    numeroAutos: '',
                                    categoria: {},
                                    vara: {},
                                    banco: {},
                                    tipoOperacao: [],
                                    atualizacaoSelic: null,
                                    compensacaoCLSaldoDevedor: null,
                                    contrato: null,
                                    depositoJudicialGarantia: null,
                                    levantamento: null,
                                    setenca: null,
                                }}
                                onSubmit={async (values, { resetForm }) => {
                                    await handleSubmitContent(values);
                                    resetForm({ values: "" });
                                }}
                            >
                                {(props) => (
                                    <Form onSubmit={props.handleSubmit}>
                                        <Tabs index={tabIndex} onChange={handleTabsChange}>
                                            <TabPanels boxShadow={cardShadow} bg="transparent" borderRadius="10px">
                                                <TabPanel style={tabStyle}>
                                                    <Box mb="30px">
                                                        <Text color={textColorSecondary} fontWeight='bold' fontSize='2xl' mt='10px' mb='4px'>
                                                            Modelo
                                                        </Text>

                                                        <Text color={textColorSecondary} fontWeight='200' fontSize='sm'>
                                                            Selecione o modelo/categoria
                                                        </Text>
                                                    </Box>
                                                    <Box mb="20px">
                                                        <FormLabel
                                                            as='legend'
                                                            display='flex'
                                                            ms='4px'
                                                            fontSize='sm'
                                                            fontWeight='500'
                                                            color={textColor}
                                                            mb='8px'>
                                                            Categoria<Text color='orange'>*</Text>
                                                        </FormLabel>
                                                        <Select
                                                            isRequired
                                                            isClearable
                                                            styles={selectStyle}
                                                            name="categoria"
                                                            placeholder='Selecione uma categoria'
                                                            options={models}
                                                            onChange={(e) => {
                                                                props.values.categoria.name = e !== null ? e.name : '';
                                                                props.values.categoria._id = e !== null ? e.value : '';
                                                                handleSetModelConfigContent(e);
                                                            }}
                                                        />
                                                    </Box>
                                                    <Flex justifyContent="end">
                                                        <Button
                                                            disabled={Object.keys(configContent).length === 0}
                                                            fontSize='sm'
                                                            variant='brand'
                                                            fontWeight='500'
                                                            backgroundColor='orange'
                                                            _hover={{ backgroundColor: '#ff4700' }}
                                                            _active={{ backgroundColor: '#db5507' }}
                                                            _focus={{ backgroundColor: '#ff4700' }}
                                                            w="150px"
                                                            h='50'
                                                            value={tabIndex + 1}
                                                            onClick={(e) => {
                                                                handleTabsChange(e);
                                                            }}
                                                        >
                                                            Avançar
                                                        </Button>
                                                    </Flex>
                                                </TabPanel>
                                                {
                                                    nextTabIndex &&
                                                    <TabPanel style={tabStyle}>
                                                        <Box mb="20px">
                                                            <Stack spacing={3}>
                                                                <Box>
                                                                    <FormLabel
                                                                        as='legend'
                                                                        display='flex'
                                                                        ms='4px'
                                                                        fontSize='sm'
                                                                        fontWeight='500'
                                                                        color={textColor}
                                                                        mb='8px'>
                                                                        Título<Text color='orange'>*</Text>
                                                                    </FormLabel>
                                                                    <Input
                                                                        value={laudoTitle}
                                                                        isRequired
                                                                        variant='auth'
                                                                        fontSize='sm'
                                                                        ms={{ base: "0px", md: "0px" }}
                                                                        type='text'
                                                                        placeholder='Ex: Metodologia'
                                                                        mb='10px'
                                                                        fontWeight='500'
                                                                        size='md'
                                                                        name="title"
                                                                        onChange={(e) => {
                                                                            setLaudoTitle(e.target.value);
                                                                            props.values.title = laudoTitle;
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <EditorConteudo
                                                                        formikProps={props}
                                                                        laudoTitle={laudoTitle}
                                                                        setLaudoTitle={setLaudoTitle}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <PeritoInfos
                                                                        formikProps={props}
                                                                        isLoading={isLoading}
                                                                        peritosOptions={peritosOptions}
                                                                        handleCreateOption={handleCreateOption}
                                                                        handleDeleteOption={handleDeleteOption}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <Honorarios
                                                                        formikProps={props}
                                                                        booleanOptions={booleanOptions}
                                                                        setajgPercentValues={setajgPercentValues}
                                                                        ajg={ajg}
                                                                        setAjg={setAjg}
                                                                        ajgPercentValues={ajgPercentValues}
                                                                        handleCalcPercentagrAJG={handleCalcPercentagrAJG}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <Recebimentos
                                                                        formikProps={props}
                                                                        booleanOptions={booleanOptions}
                                                                        setRecebimento={setRecebimento}
                                                                        setComponenteRecebimento={setComponenteRecebimento}
                                                                        recebimento={recebimento}
                                                                        recebimentosComponent={recebimentosComponent}
                                                                        handleAddRecebimento={handleAddRecebimento}
                                                                        handleRemoveRecebimento={handleRemoveRecebimento}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <Stack direction='row' h='30px' p={0} alignItems="center" mb="15px">
                                                                        <Text color={textColorSecondary} fontWeight='200' fontSize='md'>
                                                                            Localização
                                                                        </Text>

                                                                        <Divider orientation='horizontal' />
                                                                    </Stack>

                                                                    <FormLabel
                                                                        as='legend'
                                                                        display='flex'
                                                                        ms='4px'
                                                                        fontSize='sm'
                                                                        fontWeight='500'
                                                                        color={textColorSecondary}
                                                                        mb='8px'>
                                                                        Local (Vara)
                                                                    </FormLabel>
                                                                    <CreatableSelect
                                                                        components={{ Option }}
                                                                        isRequired
                                                                        isClearable
                                                                        isDisabled={isLoading.vara}
                                                                        isLoading={isLoading.vara}
                                                                        styles={selectStyle}
                                                                        name="vara"
                                                                        placeholder='Selecione...'
                                                                        formatCreateLabel={userInput => `Adicionar ${userInput}`}
                                                                        options={varaOptions}
                                                                        type="vara"
                                                                        onCreateOption={(value) => {
                                                                            handleCreateOption(value, "vara")
                                                                        }}
                                                                        onDeleteOption={handleDeleteOption}
                                                                        onChange={(e) => {
                                                                            if (e !== null) {
                                                                                props.values.vara.name = e.name;
                                                                                props.values.vara._id = e._id;
                                                                            }
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Box>
                                                                    <ConfigContent
                                                                        formikProps={props}
                                                                        configContent={configContent}
                                                                        bancoOptions={bancoOptions}
                                                                        isLoading={isLoading}
                                                                        tipoOperacaoOptions={tipoOperacaoOptions}
                                                                        handleCreateOption={handleCreateOption}
                                                                        handleDeleteOption={handleDeleteOption}
                                                                    />
                                                                </Box>
                                                            </Stack>
                                                        </Box>

                                                        <Flex justifyContent="space-between">
                                                            <Button
                                                                fontSize='sm'
                                                                variant='brand'
                                                                fontWeight='500'
                                                                backgroundColor='#ccc'
                                                                _hover={{
                                                                    backgroundColor: '#3e3e3e'
                                                                }}
                                                                _active={{
                                                                    backgroundColor: '#3e3e3e'
                                                                }}
                                                                _focus={{
                                                                    backgroundColor: '#3e3e3e'
                                                                }}
                                                                w="150px"
                                                                h='50'
                                                                value={tabIndex - 1}
                                                                onClick={(e) => {
                                                                    handleTabsChange(e);
                                                                }}
                                                            >
                                                                Voltar
                                                            </Button>
                                                            <Button
                                                                fontSize='sm'
                                                                variant='brand'
                                                                fontWeight='500'
                                                                backgroundColor='orange'
                                                                _hover={{
                                                                    backgroundColor: '#ff4700'
                                                                }}
                                                                _active={{
                                                                    backgroundColor: '#db5507'
                                                                }}
                                                                _focus={{
                                                                    backgroundColor: '#ff4700'
                                                                }}
                                                                w="150px"
                                                                h='50'
                                                                isLoading={submit}
                                                                type="submit"
                                                            >
                                                                Finalizar
                                                            </Button>
                                                        </Flex>
                                                    </TabPanel>
                                                }
                                            </TabPanels>
                                        </Tabs>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
            }
        </Box>
    );
};