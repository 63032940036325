import instance from "config/axios-config";

const axios = instance;

const createVara = async ({ name }) => {

    return await axios.post("/vara", {
        name
    }).then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });

}

const readVara = async () => {
    return await axios.get("/varas").then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });
}

const deleteVara = async (data) => {
    return await axios.delete(`/vara/${data}`).then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });
}

// eslint-disable-next-line
export {
    createVara,
    readVara,
    deleteVara,
}