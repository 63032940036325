import instance from "config/axios-config";

const axios = instance;

const createTipoOperacao = async ({ title }) => {

    return await axios.post("/tipo-operacao", {
        title
    }).then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });

}

const readTipoOperacao = async () => {
    return await axios.get("/tipo-operacoes").then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });
}

const deleteTipoOperacao = async (data) => {
    return await axios.delete(`/tipo-operacao/${data}`).then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });
}

// eslint-disable-next-line
export {
    createTipoOperacao,
    readTipoOperacao,
    deleteTipoOperacao,
}