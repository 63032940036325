// Chakra imports
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin.js";
// Layout components
import Navbar from "components/navbar/NavbarAdmin.js";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";

// Custom Chakra theme
export default function Dashboard(props) {

  const { ...rest } = props;

  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = (routes) => {

    let activeRoute = "";

    routes.map((prop) => {
      if (prop.childrens?.length > 0) {
        if (window.location.href.includes(prop.childrens.map(child => child.path)[0])) {
          activeRoute = prop.name + ' / ' + prop.childrens.map(child => child.name);
        }
      } else {
        if (
          window.location.href.indexOf(prop.layout + prop.path) !== -1
        ) {
          activeRoute = prop.name;
        }
      }

      return "";
    });

    return activeRoute;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop?.childrens?.length > 0) {
          return prop.childrens.map((child, key) => {
            return <Route
              path={child.layout + child.path}
              component={child.component}
              key={key}
            />
          })
        } else {
          return <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        }
      } else {
        return null;
      }
    });
  };

  const { onOpen } = useDisclosure();

  return (
    <Box>
      <SidebarContext.Provider

        value={{
          toggleSidebar,
          setToggleSidebar,
        }}>

        <Sidebar routes={routes} display='none' {...rest} />

        <Box
          float='right'
          minHeight='100vh'
          height='100%'
          overflow='auto'
          position='relative'
          maxHeight='100%'
          w={{ base: "100%", xl: "calc( 100% - 290px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'>

          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={"SISTEMA BASE MODELO"}
                brandText={getActiveRoute(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx='auto'
              p={{ base: "20px", md: "30px" }}
              pe='20px'
              minH='100vh'
              pt='50px'>
              <Switch>
                {getRoutes(routes)}
                <Redirect from='/' to='/admin/default' />
              </Switch>
            </Box>
          ) : null}

          <Box>
            <Footer />
          </Box>

        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
