import React from "react";

import {
    Box,
    Divider,
    FormLabel,
    Input,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Flex
} from "@chakra-ui/react";
import { textColorSecondary } from "../styles";
import Select from 'react-select';
import { NumericFormat } from "react-number-format";

export default function Honorarios(props) {

    const { formikProps, booleanOptions, setajgPercentValues, ajg, setAjg, ajgPercentValues, handleCalcPercentagrAJG } = props;

    const textColor = useColorModeValue("secondaryGray.900", "secondaryGray.900");

    const selectStyle = {
        control: (baseStyles, _) => ({
            ...baseStyles,
            borderRadius: "16px",
            borderColor: "#878cbd4d",
            color: "#878cbd4d",
            height: "2.5rem"
        }),
        option: (baseStyles, _) => ({
            ...baseStyles,
            color: "#333333",
        }),
    }

    return (
        <Box>
            <Stack direction='row' p={0} alignItems="center">
                <Text color={textColorSecondary} fontWeight='200' fontSize='md'>
                    Honorários
                </Text>

                <Divider orientation='horizontal' />
            </Stack>
            <SimpleGrid columns='1' mt="15px" mb="15px">
                <FormLabel
                    as='legend'
                    display='flex'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                >
                    Possui AJG? (Assistência Judiciária Gratuita)<Text color='orange'>*</Text>
                </FormLabel>
                <Select
                    isRequired
                    isClearable
                    isSearchable={false}
                    styles={selectStyle}
                    name="possuiAjg"
                    placeholder='Selecione'
                    options={booleanOptions}
                    onChange={(e) => {
                        setAjg(e !== null ? e.value : false);
                        formikProps.values.ajg.possuiAjg = e !== null ? e.value : false;

                        if (e !== null && e?.value === true) {
                            formikProps.values.honorario = 0;
                        }

                        if (e?.value === false || e === null) {

                            delete formikProps.values.ajg.ajgHonorario;
                            delete formikProps.values.ajg.percentage;
                            delete formikProps.values.ajg.ajgResult;;

                            setajgPercentValues({
                                honorario: null,
                                percentage: null,
                                result: null,
                            });
                        }
                    }}
                />
            </SimpleGrid>
            {
                ajg ? <SimpleGrid columns='2' gap='20px'>
                    <Box>
                        <FormLabel
                            as='legend'
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='8px'>
                            Valor honorário total (AJG)
                        </FormLabel>
                        <Input
                            variant='auth'
                            size="md"
                            as={NumericFormat}
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix={'R$ '}
                            placeholder='R$ 0,00'
                            name="honorarioAjg"
                            onValueChange={(values) => {
                                setajgPercentValues({ ...ajgPercentValues, honorario: values.floatValue !== undefined ? values.floatValue : 0 });
                                formikProps.values.ajg.ajgHonorario = values.floatValue !== undefined ? values.floatValue : 0;
                            }}
                        />
                    </Box>
                    <Box>
                        <FormLabel
                            as='legend'
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='8px'>
                            Porcentagem (%) da AJG
                        </FormLabel>
                        <NumberInput
                            disabled={ajgPercentValues.honorario === null || ajgPercentValues.honorario === 0 ? true : false}
                            variant='auth'
                            size="md"
                            step={5}
                            min={0}
                            max={100}
                            name="ajgPercentage"
                            onChange={(value) => {
                                handleCalcPercentagrAJG(value);
                                formikProps.values.ajg.percentage = value;

                                if (value === '0' || value === '') {
                                    formikProps.values.ajg.ajgResult = 0;
                                }
                            }}
                        >
                            <NumberInputField
                                placeholder='%'
                            />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Box>

                    {
                        ajgPercentValues.result !== null && ajgPercentValues.result !== 0 &&
                        <Flex w="100%" bg="#f1f1f1" p="5px" alignItems="center">
                            <Text color={textColorSecondary} fontWeight='400' fontSize='md' mr="5px">
                                Valor estimado do honorário:
                            </Text>
                            <NumericFormat
                                displayType="text"
                                prefix={'R$ '}
                                value={ajgPercentValues.result.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}
                                decimalSeparator=","
                                thousandSeparator="."
                                name="ajgResult"
                                onValueChange={(values) => {
                                    formikProps.values.ajg.ajgResult = values.floatValue !== undefined ? values.floatValue : 0;
                                }}
                            />
                        </Flex>
                    }
                </SimpleGrid>
                    : <Box>
                        <FormLabel
                            as='legend'
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='8px'>
                            Valor do honorário total
                        </FormLabel>
                        <Input
                            variant='auth'
                            size="md"
                            as={NumericFormat}
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix={'R$ '}
                            placeholder='R$ 0,00'
                            onValueChange={(values) => {
                                formikProps.values.honorario = values.floatValue !== undefined ? values.floatValue : 0;
                            }}
                        />
                    </Box>
            }

        </Box>
    )
};