import React, { useEffect, useState, useContext, useCallback } from "react";
import { ApiContext } from "contexts/ApiContext";

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { toast } from "react-toastify";
import { formatRealMask } from "utils/formatRealMask";
import { formatDateMask } from "utils/formatDateMask";

export default function ModalConteudo(props) {
  const { getTextContent } = useContext(ApiContext);

  const [isLoading, setIsLoading] = useState(true);
  const [documentContent, setDocumentContent] = useState("");
  const { isOpen, setOpenModalConteudo, data } = props;

  const modalHeader = {
    background: "linear-gradient(to right,#8419a7,#661263)",
    color: "white",
    borderRadius: "5px",
  };

  const navStyle = {
    fontSize: "14px",
    fontWeight: "400",
    marginTop: "5px",
  };

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  function handleCloseModal() {
    setOpenModalConteudo(false);
  }

  const getContent = useCallback(async () => {
    const documentContent = await getTextContent(data.content);

    if (documentContent.status === 400 || documentContent.status === 401) {
      setIsLoading(false);
      toast.error("Ops, ocorreu um erro ao carregar os conteúdo.");
      return false;
    }

    setIsLoading(false);
    setDocumentContent(documentContent.data.data);
  }, [data.content, getTextContent]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  return (
    <>
      <Modal onClose={handleCloseModal} isOpen={isOpen}>
        <ModalContent
          bg="transparent"
          m="0"
          p="0"
          maxWidth="100vw"
          height="100vh"
          borderRadius="0"
        >
          <Grid
            gridTemplateRows={"1fr"}
            gridTemplateColumns={"1fr 3fr"}
            gap="0"
            color="blackAlpha.700"
            fontWeight="bold"
            height="100vh"
          >
            <GridItem
              area={"1 / 1 / 6 / 1"}
              display="flex"
              flexDirection="column"
              bg="#f7f7f7"
              p="15px"
              overflow="auto"
            >
              <Box mb="10px">
                <Button onClick={handleCloseModal}>Voltar</Button>
              </Box>

              <Box>
                <Box style={modalHeader}>
                  <ModalHeader fontSize="13px">{data.title}</ModalHeader>
                </Box>

                {data.tipoOperacao.length > 0 && (
                  <Card
                    bg="white"
                    boxShadow={cardShadow}
                    borderRadius="5px"
                    mt="5px"
                    p="5px 10px"
                  >
                    {data.tipoOperacao.length > 0 ? (
                      <Box>
                        <Text style={navStyle} mb="5px">
                          Tipo operação:
                        </Text>
                        {data.tipoOperacao.map((op, index) => (
                          <Text key={index} style={navStyle}>
                            <strong>{op.title}</strong>
                          </Text>
                        ))}
                      </Box>
                    ) : (
                      <Text style={navStyle}>
                        {" "}
                        Tipo operação: {<strong>Nenhum</strong>}{" "}
                      </Text>
                    )}
                  </Card>
                )}

                <Card
                  bg="white"
                  boxShadow={cardShadow}
                  borderRadius="5px"
                  mt="5px"
                  p="5px 10px"
                >
                  <Text style={navStyle}>
                    Atualização Selic:{" "}
                    <strong>{data.atualizacaoSelic ? "Sim" : "Não"}</strong>
                  </Text>

                  <Text style={navStyle}>
                    Banco: <strong>{data.banco.name}</strong>
                  </Text>

                  <Text style={navStyle}>
                    Categoria: <strong>{data.categoria.name}</strong>
                  </Text>

                  <Text style={navStyle}>
                    Compensação CL Saldo Devedor:{" "}
                    <strong>
                      {data.compensacaoCLSaldoDevedor ? "Sim" : "Não"}
                    </strong>
                  </Text>

                  <Text style={navStyle}>
                    Contrato: <strong>{data.contrato ? "Sim" : "Não"}</strong>
                  </Text>

                  <Text style={navStyle}>
                    Data Entrega:{" "}
                    <strong>
                      {data.dataEntrega !== null
                        ? formatDateMask(data.dataEntrega)
                        : "-"}
                    </strong>
                  </Text>

                  <Text style={navStyle}>
                    Depósito Judicial/Garantia:{" "}
                    <strong>
                      {data.depositoJudicialGarantia ? "Sim" : "Não"}
                    </strong>
                  </Text>

                  <Text style={navStyle}>
                    Levantamento:{" "}
                    <strong>{data.levantamento ? "Sim" : "Não"}</strong>
                  </Text>

                  <Text style={navStyle}>
                    Nº dos Autos: <strong>{data.numeroAutos}</strong>
                  </Text>

                  <Text style={navStyle}>
                    Perito Nomeado: <strong>{data.perito.name}</strong>
                  </Text>

                  <Text style={navStyle}>
                    Setença: <strong>{data.setenca ? "Sim" : "Não"}</strong>
                  </Text>

                  <Text style={navStyle}>
                    Local (Vara): <strong>{data.vara.name}</strong>
                  </Text>
                </Card>

                {(data.honorario !== 0 || data.honorario !== null) && (
                  <Card
                    bg="white"
                    boxShadow={cardShadow}
                    borderRadius="5px"
                    mt="5px"
                    p="5px 10px"
                  >
                    <Text style={navStyle}>
                      {data.honorario !== 0 && (
                        <>
                          {" "}
                          Valor Honorário: <strong>{formatRealMask(data.honorario)}</strong>
                        </>
                      )}
                    </Text>
                  </Card>
                )}

                {Object.keys(data.ajg).length > 0 && (
                  <Card
                    bg="white"
                    boxShadow={cardShadow}
                    borderRadius="5px"
                    mt="5px"
                    p="5px 10px"
                  >
                    {Object.keys(data.ajg).length > 0 && (
                      <Box>
                        <Text style={navStyle}>
                          {data.ajg.possuiAjg ? (
                            <>
                              <Text>Assistência Gratuita:</Text>
                              <Accordion allowMultiple>
                                <AccordionItem>
                                  <AccordionButton>
                                    <Box
                                      as="span"
                                      flex="1"
                                      textAlign="left"
                                      fontSize="14px"
                                    >
                                      Valores AJG:
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>

                                  <AccordionPanel pb={4}>
                                    <Text style={navStyle}>
                                      Valor honorário:{" "}
                                      <strong>
                                        R$ {formatRealMask(data.ajg.ajgHonorario)}
                                      </strong>
                                    </Text>
                                    <Text style={navStyle}>
                                      Porcentagem:{" "}
                                      <strong>{data.ajg.percentage + "%"}</strong>
                                    </Text>
                                    <Text style={navStyle}>
                                      Total:{" "}
                                      <strong>R$ {formatRealMask(data.ajg.ajgResult)}</strong>
                                    </Text>
                                  </AccordionPanel>
                                </AccordionItem>
                              </Accordion>
                            </>
                          ) : (
                            <Text>Possui Ajg: {<strong>Não</strong>}</Text>
                          )}
                        </Text>
                      </Box>
                    )}
                  </Card>
                )}

                {data.recebimento[0].possuiRecebimento && (
                  <Card
                    bg="white"
                    boxShadow={cardShadow}
                    borderRadius="5px"
                    mt="5px"
                    p="5px 10px"
                  >
                    {data.recebimento[0].possuiRecebimento ? (
                      <Box>
                        <Text style={navStyle} mb="15px">
                          {data.recebimento[0].recebidos.length > 0 ? (
                            <>
                              <Text style={navStyle} mt="15px" mb="5px">
                                Recebimentos:
                              </Text>
                              <Accordion allowMultiple>
                                {data.recebimento[0].recebidos.map(
                                  (rcb, index) => (
                                    <AccordionItem key={index}>
                                      <AccordionButton>
                                        <Box
                                          as="span"
                                          flex="1"
                                          textAlign="left"
                                          fontSize="14px"
                                        >
                                          {rcb.tipoRecebimento !== ""
                                            ? rcb.tipoRecebimento
                                                .charAt(0)
                                                .toUpperCase() +
                                              rcb.tipoRecebimento.slice(1)
                                            : "Outros"}
                                        </Box>
                                        <AccordionIcon />
                                      </AccordionButton>

                                      <AccordionPanel pb={4}>
                                        <Text style={navStyle}>
                                          Data recebimento:{" "}
                                          {rcb.dataRecebimento !== "" ? (
                                            <strong>
                                              {formatDateMask(rcb.dataRecebimento)}
                                            </strong>
                                          ) : (
                                            ""
                                          )}
                                        </Text>
                                        <Text style={navStyle}>
                                          Valor recebido:{" "}
                                          <strong>
                                            {formatRealMask(rcb.valorRecebido)}
                                          </strong>
                                        </Text>
                                      </AccordionPanel>
                                    </AccordionItem>
                                  )
                                )}
                              </Accordion>
                            </>
                          ) : (
                            <></>
                          )}
                        </Text>
                      </Box>
                    ) : (
                      <Text style={navStyle}>
                        {" "}
                        Possui Recebimentos: {<strong>Não</strong>}{" "}
                      </Text>
                    )}
                  </Card>
                )}
              </Box>
            </GridItem>

            <GridItem area={"1 / 2 / 6 / 6"} bg="white" p="20px">
              {isLoading ? (
                <Flex justifyContent="center" alignItems="center" height="100%">
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="gray.500"
                    size="xl"
                  />
                </Flex>
              ) : (
                <Box
                  dangerouslySetInnerHTML={{ __html: documentContent }}
                  height="100%"
                  overflow="auto"
                  border="1px solid #f1f1f1"
                  p="30px"
                />
              )}
            </GridItem>
          </Grid>
        </ModalContent>
      </Modal>
    </>
  );
}
