import React from "react";

import {
    Box,
    Button,
    FormLabel,
    Stack,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";

import Select from 'react-select';

import ListRecebimentos from "./ListRecebimentos";

export default function Recebimentos(props) {

    const {
        formikProps,
        booleanOptions,
        recebimento,
        setRecebimento,
        recebimentosComponent,
        setComponenteRecebimento,
        handleAddRecebimento,
        handleRemoveRecebimento,
    } = props;

    const textColor = useColorModeValue("secondaryGray.900", "secondaryGray.900");

    const selectStyle = {
        control: (baseStyles, _) => ({
            ...baseStyles,
            borderRadius: "16px",
            borderColor: "#878cbd4d",
            color: "#878cbd4d",
            height: "2.5rem"
        }),
        option: (baseStyles, _) => ({
            ...baseStyles,
            color: "#333333",
        }),
    }

    return (
        <>
            <Box mt="20px">
                <FormLabel
                    as='legend'
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Possui recebimento?<Text color='orange'>*</Text>
                </FormLabel>
                <Select
                    isRequired
                    isClearable
                    isSearchable={false}
                    styles={selectStyle}
                    name="recebimento"
                    placeholder='Selecione'
                    options={booleanOptions}
                    onChange={(e) => {
                        formikProps.values.recebimento.possuiRecebimento = e !== null ? e.value : false;
                        setRecebimento(e !== null ? e.value : false);

                        if (e === null || e.value === false) {
                            formikProps.values.recebimento.recebidos = [];
                            setComponenteRecebimento([]);
                        }
                    }}
                />
            </Box>
            {
                recebimento &&
                <Box mb="30px" mt="30px" bg="#f1f1f187" p="15px" borderRadius="10px">
                    <Box>
                        {
                            recebimentosComponent.length > 0 &&
                            recebimentosComponent.map((item, index) => (
                                <ListRecebimentos
                                    key={index}
                                    props={item}
                                    index={index}
                                    handleRemoveRecebimento={handleRemoveRecebimento}
                                />))
                        }
                    </Box>
                    <Stack direction='row' spacing={4} align='center' justifyContent="center">
                        <Button
                            mt="10px"
                            colorScheme='teal'
                            variant='outline'
                            size='sm'
                            onClick={() => {
                                formikProps.values.recebimento.recebidos.push({
                                    dataRecebimento: '',
                                    valorRecebido: 0,
                                    tipoRecebimento: '',
                                });
                                handleAddRecebimento(props)
                            }}
                        >
                            Adicionar recebimento
                        </Button>
                    </Stack>
                </Box>
            }
        </>
    )
};