import instance from "config/axios-config";

const axios = instance;

const login = async (username, password) => {

    return await axios
        .post("/signin", {
            username,
            password
        }).then((response) => {

            if (response.status !== 404 && response.status !== 401 && response?.data?.accessToken !== null) {
                const userStored = localStorage.getItem("user");

                if (userStored !== null) {
                    localStorage.removeItem("user");
                }
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return {
                data: response.data,
                status: response.status
            };
        })
        .catch((error) => {
            return {
                data: error?.response.data,
                status: error?.response.status
            };
        });
}

const logout = () => {
    localStorage.removeItem("user");
}

// eslint-disable-next-line
export {
    login,
    logout
}