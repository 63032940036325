import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";

import { Formik, Form } from 'formik';

// Custom components
import DefaultAuth from "layouts/auth/Default";

// Assets
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { AuthContext } from "contexts/AuthContext";

import { Buffer } from "buffer";

function SignIn() {

  let history = useHistory();

  const { user, setUser, signIn } = useContext(AuthContext);

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const [show, setShow] = React.useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");

  function handleClick() { setShow(!show) };

  async function handleLogin(value, { setSubmitting }) {

    setSubmitting(true);
    const hashPassword = Buffer.from(value.password).toString('base64');
    const login = await signIn(value.username, hashPassword);

    if (login.status === 401) {
      setErrorMessage("Ops, usuário ou senha inválidos.");
      setSubmitting(false);
      return false;
    }

    if (login.status === 404) {
      setErrorMessage("Ops, usuário não encontrado.");
      setSubmitting(false);
      return false;
    }

    setSubmitting(false);
    setErrorMessage("");

    history.go('/admin/default');

    return true;
  }

  useEffect(() => {

    const storeUserData = JSON.parse(localStorage.getItem("user"));
    
    if (user !== null && user?.accessToken !== null && user?.accessToken !== undefined) {
      history.push("/admin");
    } else if (storeUserData && storeUserData.accessToken === undefined) {
      localStorage.removeItem("user");
      setUser(null);
    }

  });

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Entrar
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Digite seu usuário e senha para entrar!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <Formik
            initialValues={{ username: '', password: '' }}
            onSubmit={async (values, { setSubmitting }) => {
              await handleLogin(values, { setSubmitting });
            }}
          >
            {(props) => (
              <Form onSubmit={props.handleSubmit}>
                <FormControl>
                  <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Usuário<Text color='orange'>*</Text>
                  </FormLabel>
                  <Input
                    isRequired
                    variant='auth'
                    fontSize='sm'
                    ms={{ base: "0px", md: "0px" }}
                    type='text'
                    placeholder='Seu usuário'
                    mb='24px'
                    fontWeight='500'
                    size='lg'
                    name="username"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.username}
                  />
                  <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                    Senha<Text color='orange'>*</Text>
                  </FormLabel>
                  <InputGroup size='md'>
                    <Input
                      isRequired
                      fontSize='sm'
                      placeholder='Senha'
                      mb='24px'
                      size='lg'
                      type={show ? "text" : "password"}
                      variant='auth'
                      name="password"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.password}
                    />
                    <InputRightElement display='flex' alignItems='center' mt='4px'>
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <Button
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    backgroundColor='orange'
                    _hover={{
                      backgroundColor: '#ff4700'
                    }}
                    _active={{
                      backgroundColor: '#db5507'
                    }}
                    _focus={{
                      backgroundColor: '#ff4700'
                    }}
                    w='100%'
                    h='50'
                    mb='24px'
                    isLoading={props.isSubmitting}
                    type="submit"
                  >
                    Entrar
                  </Button>

                  {ErrorMessage !== '' ?
                    <Alert status='warning'>
                      <AlertIcon />
                      <AlertDescription>{ErrorMessage}</AlertDescription>
                    </Alert> : <></>}
                </FormControl>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
