import React from "react";

// Chakra imports
import { Box } from "@chakra-ui/react";
import ConsultarConteudo from "views/admin/modelos/consultarModelos/components/ConsultarConteudo";


export default function ConsultarModelos(props) {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <ConsultarConteudo
                props={props}
                title="Consultar Laudos"
            />
            
        </Box>
    );
}