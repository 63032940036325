import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdCategory,
  MdArticle
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";

// Auth Imports
import SignInCentered from "views/auth/signIn";

// Components
import Category from "views/admin/category";
import Modelos from "views/admin/modelos/inserirModelos";
import consultarModelos from "views/admin/modelos/consultarModelos";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Login",
    layout: "/auth",
    path: "/login",
    component: SignInCentered,
  },
  {
    name: "Modelos",
    path: "/modelo",
    icon: <Icon as={MdCategory} width='20px' height='20px' color='inherit' />,
    layout: "/admin",
    childrens: [
      {
        name: "Configurar Modelos",
        layout: "/admin",
        path: "/modelo/config",
        component: Category,
      }
    ]
  },
  {
    name: "Laudos",
    path: "/conteudos",
    icon: <Icon as={MdArticle} width='20px' height='20px' color='inherit' />,
    layout: "/admin",
    childrens: [
      {
        name: "Inserir Laudo",
        layout: "/admin",
        path: "/conteudos/novo",
        component: Modelos,
      },
      {
        name: "Consultar Laudos",
        layout: "/admin",
        path: "/conteudos/laudos",
        component: consultarModelos,
      }
    ]
  }
];

export default routes;
