import instance from "config/axios-config";

const axios = instance;

const createCategory = async (data) => {

    return await axios.post("/category", {
        data
    }).then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });

}

const readCategory = () => {
    return true;
}

const readCategories = async () => {
    return await axios.get("/categories").then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });
}

const updateCategory = async (data) => {

    return await axios.put(`/category/${data.id}`, {
        ...data
    }).then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });
}

const deleteCategory = async (data) => {
    return await axios.delete(`/category/${data.id}`).then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });
}

// eslint-disable-next-line
export {
    createCategory,
    readCategory,
    readCategories,
    updateCategory,
    deleteCategory
}