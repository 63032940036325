import instance from "config/axios-config";

const axios = instance;

const readBancos = async () => {
    return await axios.get("/bancos").then((response) => {

        return {
            data: response.data,
            status: response.status
        };

    }).catch((error) => {
        return {
            data: error?.response.data,
            status: error?.response.status
        };
    });
}

// eslint-disable-next-line
export {
    readBancos
}