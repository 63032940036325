export function treathShowConfig(config) {

    const treathConfig = {
        atualizacaoSelic: {
            name: "Atualização Selic",
            active: config.atualizacaoSelic
        },
        banco: {
            name: "Banco",
            active: config.banco
        },
        compensacaoCLSaldoDevedor: {
            name: "Compensação CL Saldo Devedor",
            active: config.compensacaoCLSaldoDevedor
        },
        depositoJudicialGarantia: {
            name: "Depósito Judicial Garantia",
            active: config.depositoJudicialGarantia
        },
        levantamento: {
            name: "Levantamento",
            active: config.levantamento
        },
        numeroAutos: {
            name: "Número dos Autos",
            active: config.numeroAutos
        },
        setenca: {
            name: "Setença",
            active: config.setenca
        },
        tipoOperacao: {
            name: "Tipo Operação",
            active: config.tipoOperacao
        },
        contrato: {
            name: "Contrato",
            active: config.contrato
        }
    };

    const entries = Object.keys(config);

    return entries.map((key) => treathConfig[key].active === true ? `${treathConfig[key].name}; ` : "");
};

export function treathShowConfigAttributes(config) {

    const treathConfig = {
        numeroAutos: {
            value: "numeroAutos",
            name: "Número dos Autos",
            active: config.numeroAutos,
            type: "input"
        },
        banco: {
            value: "banco",
            name: "Banco",
            active: config.banco,
            type: "select"
        },
        tipoOperacao: {
            value: "tipoOperacao",
            name: "Tipo Operação",
            active: config.tipoOperacao,
            type: "combobox"
        },
        atualizacaoSelic: {
            value: "atualizacaoSelic",
            name: "Atualização Selic?",
            active: config.atualizacaoSelic,
            type: "boolean"
        },
        compensacaoCLSaldoDevedor: {
            value: "compensacaoCLSaldoDevedor",
            name: "Compensação CL Saldo Devedor?",
            active: config.compensacaoCLSaldoDevedor,
            type: "boolean",
        },
        depositoJudicialGarantia: {
            value: "depositoJudicialGarantia",
            name: "Depósito Judicial/Garantia?",
            active: config.depositoJudicialGarantia,
            type: "boolean"
        },
        levantamento: {
            value: "levantamento",
            name: "Levantamento?",
            active: config.levantamento,
            type: "boolean"
        },
        setenca: {
            value: "setenca",
            name: "Setença?",
            active: config.setenca,
            type: "boolean"
        },
        contrato: {
            value: "contrato",
            name: "Contrato?",
            active: config.contrato,
            type: "boolean"
        }
    };

    return Object.values(treathConfig);
};