import { useState } from 'react';
import { AuthContext } from "contexts/AuthContext";

import { login, logout } from "services/auth-service";

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);

    async function signIn(username, password) {
        const data = await login(username, password);

        if (data && data?.roles && data?.roles[0] === "ROLE_ADMIN" && data.accessToken !== null) {
            setUser({
                userId: data.id,
                username: data.username,
                email: data.email,
                roles: data.roles
            });
        }

        return data;
    }

    function logoutUser() {
        logout();
        setUser(null);
    }

    return (
        <AuthContext.Provider value={{ user, setUser, signIn, logoutUser }}>
            {children}
        </AuthContext.Provider>
    );
}