import React, { useContext, useState, useEffect, useCallback } from "react";
import {
    Spinner,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Text,
    Icon,
    useColorModeValue,
    Tooltip
} from "@chakra-ui/react";

// Custom components
import ModalCategory from "./ModalCategory";
import SwitchField from "components/fields/SwitchField";
import Card from "components/card/Card.js";

import { Formik, Form } from 'formik';
import { ApiContext } from "contexts/ApiContext";

import { ToastContainer, toast } from 'react-toastify';

import {
    parseISO,
} from 'date-fns';

import { format } from 'date-fns-tz';

import { MdDelete, MdEdit } from "react-icons/md";
import { ViewIcon } from '@chakra-ui/icons';
import { treathShowConfig } from "utils/treathConfig";

export default function CategoryConfig(props) {

    const { createCategory, readCategories, updateCategory, deleteCategory } = useContext(ApiContext);
    const { title, value, ...rest } = props;

    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "gray.400";
    const textColor = useColorModeValue("navy.700", "white");
    const bg = useColorModeValue("white", "navy.700");

    const cardShadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "unset"
    );

    const fontSize = "sm";

    // STATES
    const [loading, setLoading] = useState(true);
    const [models, setModels] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState(null);
    const [type, setType] = useState("");

    const handleOpenModal = (modelo, type) => {
        setSelectedModel(modelo);
        setIsModalOpen(true);
        setType(type);
    }

    const handleCloseModal = () => {
        setSelectedModel(null);
        setIsModalOpen(false);
    }

    async function handleCreateCategory(value, { setSubmitting }) {

        setSubmitting(true);
        const create = await createCategory(value);

        if (create.status === 400 || create.status === 401) {
            toast.error("Ops, ocorreu um erro ao salvar o modelo.")
            return false
        }

        if (create.status === 202) {
            toast.warn("Ops, modelo já existente.")
            return false
        }

        toast.success("Modelo cadastrado com sucesso!");
        getModels();
    }

    async function handleEditCategory(value, { setSubmitting }) {
        setSubmitting(true);
        const edit = await updateCategory(value);


        if (edit.status === 400 || edit.status === 401 || edit.status === 404) {
            toast.error("Ops, ocorreu um erro ao editar o modelo.")
            return false
        }

        toast.success("Modelo editado com sucesso!");
        getModels();

        setTimeout(() => setIsModalOpen(false), 2000);
    }

    async function handleDeleteCategory(value, { setSubmitting }) {
        setSubmitting(true);
        const remove = await deleteCategory(value);


        if (remove.status === 400 || remove.status === 401 || remove.status === 404) {
            toast.error("Ops, ocorreu um erro ao remover o modelo.")
            return false
        }

        toast.success("Modelo removido com sucesso!");
        getModels();

        setTimeout(() => setIsModalOpen(false), 2000);
    }

    const getModels = useCallback(async () => {
        const requestModels = await readCategories();

        if (requestModels.status === 400 || requestModels.status === 401) {
            setLoading(false);
            toast.error("Ops, ocorreu um erro ao carregar os modelos.")
            return false
        }

        setLoading(false);
        setModels(requestModels.data.data.map(dt => { return { ...dt, created_at: format(parseISO(dt.created_at), "dd/MM/yyyy"), timeZone: 'America/Sao_Paulo' } }));
    }, [readCategories]);

    useEffect(() => {
        getModels();
    }, [getModels]);

    return (
        <>
            <Card bg={bg} {...rest} mb="30px">
                <Box mb="30px">
                    <Text
                        color={textColorPrimary}
                        fontWeight='bold'
                        fontSize='2xl'
                        mt='10px'
                        mb='4px'
                    >
                        {title}
                    </Text>

                    <Text color={textColorSecondary} fontWeight='200' fontSize='sm'>
                        {value}
                    </Text>
                </Box>
                <Formik
                    initialValues={
                        {
                            name: '',
                            config: {
                                numeroAutos: false,
                                banco: false,
                                tipoOperacao: false,
                                depositoJudicialGarantia: false,
                                compensacaoCLSaldoDevedor: false,
                                levantamento: false,
                                atualizacaoSelic: false,
                                setenca: false,
                                contrato: false,
                            }
                        }
                    }
                    onSubmit={async (values, { resetForm, setSubmitting }) => {
                        await handleCreateCategory(values, { setSubmitting });
                        resetForm({ values: "" });
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit}>
                            <FormControl>
                                <Grid mb="20px">
                                    <Box>
                                        <FormLabel
                                            display='flex'
                                            ms='4px'
                                            fontSize='sm'
                                            fontWeight='500'
                                            color={textColor}
                                            mb='8px'>
                                            Título do modelo<Text color='orange'>*</Text>
                                        </FormLabel>
                                        <Input
                                            isRequired
                                            variant='auth'
                                            fontSize='sm'
                                            ms={{ base: "0px", md: "0px" }}
                                            type='text'
                                            placeholder='Ex: Metodologia'
                                            mb='10px'
                                            fontWeight='500'
                                            size='lg'
                                            name="name"
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.name}
                                        />
                                    </Box>

                                    <Box mt="20px">
                                        <Text
                                            color={textColorSecondary}
                                            fontWeight='bold'
                                            fontSize='md'
                                            mt='5px'
                                            mb='4px'
                                        >
                                            Configurações
                                        </Text>

                                        <Text color={textColorSecondary} fontWeight='200' fontSize='sm'>
                                            Configure os campos a serem mostrados para o modelo
                                        </Text>

                                        <Flex mt="10px" justifyContent="flex-start" wrap="wrap">
                                            <SwitchField
                                                reversed={true}
                                                fontSize={fontSize}
                                                id="0"
                                                label="Nº de Autos"
                                                onChangeCheck={(e) => props.values.config.numeroAutos = e.target.checked}
                                            />

                                            <SwitchField
                                                reversed={true}
                                                fontSize={fontSize}
                                                id="1"
                                                label="Bancos"
                                                onChangeCheck={(e) => props.values.config.banco = e.target.checked}
                                            />

                                            <SwitchField
                                                reversed={true}
                                                fontSize={fontSize}

                                                id="2"
                                                label="Tipo de op."
                                                onChangeCheck={(e) => props.values.config.tipoOperacao = e.target.checked}
                                            />

                                            <SwitchField
                                                reversed={true}
                                                fontSize={fontSize}

                                                id="3"
                                                label="Depo. Judi/Garantia"
                                                onChangeCheck={(e) => props.values.config.depositoJudicialGarantia = e.target.checked}
                                            />

                                            <SwitchField
                                                reversed={true}
                                                fontSize={fontSize}

                                                id="4"
                                                label="Comp. CL/Saldo Devedor"
                                                onChangeCheck={(e) => props.values.config.compensacaoCLSaldoDevedor = e.target.checked}
                                            />

                                            <SwitchField
                                                reversed={true}
                                                fontSize={fontSize}

                                                id="5"
                                                label="Levantamento"
                                                onChangeCheck={(e) => props.values.config.levantamento = e.target.checked}
                                            />

                                            <SwitchField
                                                reversed={true}
                                                fontSize={fontSize}

                                                id="6"
                                                label="At. pela Selic"
                                                onChangeCheck={(e) => props.values.config.atualizacaoSelic = e.target.checked}
                                            />

                                            <SwitchField
                                                reversed={true}
                                                fontSize={fontSize}
                                                id="7"
                                                label="Sentença"
                                                onChangeCheck={(e) => props.values.config.setenca = e.target.checked}
                                            />

                                            <SwitchField
                                                reversed={true}
                                                fontSize={fontSize}
                                                id="8"
                                                label="Contrato"
                                                onChangeCheck={(e) => props.values.config.contrato = e.target.checked}
                                            />
                                        </Flex>
                                    </Box>
                                </Grid>
                                <Card boxShadow={cardShadow}>
                                    <Button
                                        fontSize='sm'
                                        variant='brand'
                                        fontWeight='500'
                                        backgroundColor='orange'
                                        _hover={{
                                            backgroundColor: '#ff4700'
                                        }}
                                        _active={{
                                            backgroundColor: '#db5507'
                                        }}
                                        _focus={{
                                            backgroundColor: '#ff4700'
                                        }}
                                        w='100%'
                                        h='50'
                                        isLoading={props.isSubmitting}
                                        type="submit"
                                    >
                                        Salvar
                                    </Button>
                                </Card>
                            </FormControl>
                        </Form>
                    )}
                </Formik>
                <ToastContainer />
            </Card>
            {
                loading ? <div className="loading">
                    <Flex justifyContent="center">
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='gray.500'
                            size='xl'
                        />
                    </Flex>
                </div>
                    :
                    <Card bg="transparent" p="0px" {...rest}>
                        <Box mb="30px">
                            <Text
                                color={textColorPrimary}
                                fontWeight='bold'
                                fontSize='2xl'
                                mt='10px'
                                mb='4px'
                            >
                                Modelos Configurados
                            </Text>
                        </Box>

                        {
                            models.length > 0 ?
                                <Card bg={bg} {...rest}>
                                    {
                                        models.map((modelo, index) => {
                                            return <Card key={index} mb="15px" boxShadow={cardShadow}>
                                                <Flex align='center' justifyContent="space-between" direction={{ base: "column", md: "row" }}>
                                                    <Box mt={{ base: "10px", md: "0" }}>
                                                        <Text
                                                            color={textColorPrimary}
                                                            fontWeight='500'
                                                            fontSize='lg'
                                                            mb='4px'>
                                                            {modelo.name}
                                                        </Text>
                                                        <Text
                                                            fontWeight='100'
                                                            color={textColorSecondary}
                                                            fontSize='xs'
                                                            me='4px'>
                                                            Criado em {modelo.created_at}
                                                        </Text>
                                                    </Box>
                                                    <Box mt={{ base: "10px", md: "0" }}>
                                                        <Tooltip key={index} label={treathShowConfig(modelo.config)} color="#ccc" bg={bg}>
                                                            <ViewIcon color='secondaryGray.500' h='18px' w='18px' mr="5px" verticalAlign="initial" />
                                                        </Tooltip>
                                                        <Icon id={index} className="edit" as={MdEdit} color='secondaryGray.500' h='18px' w='18px' mr="5px" onClick={() => handleOpenModal(modelo, "edit")} />
                                                        <Icon id={index} className="delete" as={MdDelete} color='secondaryGray.500' h='18px' w='18px' onClick={() => handleOpenModal(modelo, "delete")} />
                                                    </Box>
                                                </Flex>
                                            </Card>
                                        })
                                    }
                                </Card> :
                                <Text
                                    color={textColorPrimary}
                                    mt='10px'
                                    mb='4px'
                                >
                                    Nenhum modelo encontrado.
                                </Text>
                        }
                    </Card>
            }

            {
                isModalOpen && (
                    <ModalCategory
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                        data={selectedModel}
                        handleEditCategory={handleEditCategory}
                        handleDeleteCategory={handleDeleteCategory}
                        type={type}
                    />)
            }
        </>
    );
}
