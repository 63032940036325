import React, { useContext, useState, useEffect, useCallback } from "react";

import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Select from "react-select";

import Card from "components/card/Card";
import { SearchIcon } from "@chakra-ui/icons";
import ListaConteudos from "./ListaConteudos";

import { ApiContext } from "contexts/ApiContext";
import { ToastContainer, toast } from "react-toastify";

export default function ConsultarConteudo(props) {
  const { getContentModel } = useContext(ApiContext);

  const textColor = useColorModeValue("secondaryGray.900", "secondaryGray.900");
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const selectStyle = {
    control: (baseStyles, _) => ({
      ...baseStyles,
      borderRadius: "16px",
      borderColor: "#878cbd4d",
      color: "#878cbd4d",
      height: "2.5rem",
    }),
    option: (baseStyles, _) => ({
      ...baseStyles,
      color: "#333333",
    }),
  };

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  // STATES
  const [loading, setLoading] = useState(true);
  const [contentModel, setContentModel] = useState([]);

  const getContentModels = useCallback(async () => {
    const requestContentModels = await getContentModel();

    if (
      requestContentModels.status === 400 ||
      requestContentModels.status === 401
    ) {
      setLoading(false);
      toast.error("Ops, ocorreu um erro ao carregar os modelos.");
      return false;
    }

    setLoading(false);
    setContentModel(requestContentModels.data.data)
  }, [getContentModel]);

  useEffect(() => {
    getContentModels();
}, [getContentModels]);

  return (
    <Box>
      <Box mb="20px">
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          {props.title}
        </Text>
      </Box>

      <Card bg="white" boxShadow={cardShadow}>
        <Box>
          <SimpleGrid columns="1" gap="10px">
            <Box mb="20px">
              <FormLabel
                as="legend"
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Pesquisar por título/conteúdo
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  variant="auth"
                  type="search"
                  placeholder="Pesquisar..."
                />
              </InputGroup>
            </Box>
          </SimpleGrid>

          <SimpleGrid columns="4" gap="10px">
            <Box>
              <FormLabel
                as="legend"
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Categoria
              </FormLabel>
              <Select
                isRequired
                isClearable
                styles={selectStyle}
                name="categoria"
                placeholder="Selecione..."
              />
            </Box>

            <Box>
              <FormLabel
                as="legend"
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Data entrega
              </FormLabel>
              <Input
                isRequired
                variant="auth"
                placeholder="Selecione a data"
                size="md"
                name="dataEntrega"
                type="date"
              />
            </Box>

            <Box>
              <FormLabel
                as="legend"
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Perito
              </FormLabel>
              <Select
                isRequired
                isClearable
                styles={selectStyle}
                name="perito"
                placeholder="Selecione..."
              />
            </Box>
            <Box>
              <FormLabel
                as="legend"
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Local (Vara)
              </FormLabel>
              <Select
                isRequired
                isClearable
                styles={selectStyle}
                name="vara"
                placeholder="Selecione..."
              />
            </Box>
            <Box>
              <FormLabel
                as="legend"
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Número dos autos
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Ex: 123"
                mb="10px"
                fontWeight="500"
                size="md"
                name="numero-autos"
              />
            </Box>

            <Box>
              <FormLabel
                as="legend"
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Banco
              </FormLabel>
              <Select
                isRequired
                isClearable
                styles={selectStyle}
                name="banco"
                placeholder="Selecione..."
              />
            </Box>

            <Box>
              <FormLabel
                as="legend"
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Tipo Operação
              </FormLabel>
              <Select
                isRequired
                isClearable
                styles={selectStyle}
                name="tipoOperacao"
                placeholder="Selecione..."
              />
            </Box>

            <Box>
              <FormLabel
                as="legend"
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Opções gerais
              </FormLabel>
              <Select
                isRequired
                isClearable
                styles={selectStyle}
                name="opcoesGerais"
                placeholder="Selecione..."
              />
            </Box>
          </SimpleGrid>

          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            backgroundColor="orange"
            _hover={{
              backgroundColor: "#ff4700",
            }}
            _active={{
              backgroundColor: "#db5507",
            }}
            _focus={{
              backgroundColor: "#ff4700",
            }}
            mt="15px"
            w="100%"
            h="10"
            type="submit"
          >
            Pesquisar
          </Button>
        </Box>
      </Card>

      {loading ? (
        <div className="loading">
          <Flex justifyContent="center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="gray.500"
              size="xl"
            />
          </Flex>
        </div>
      ) : (
        <ListaConteudos data={contentModel} />
      )}

      <ToastContainer />
    </Box>
  );
}
