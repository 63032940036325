import { useContext, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { AuthContext } from "contexts/AuthContext";

import jwt_decode from "jwt-decode";


export const AuthManager = ({ children }) => {

    const { user, setUser } = useContext(AuthContext);
    const [isInvalidAccessToken, setIsInvalidAccessToken] = useState(false);

    const userStore = JSON.parse(localStorage.getItem("user")) || null;

    useEffect(() => {
        let interval = null;

        if (!isInvalidAccessToken) {
            if (userStore !== null) {

                interval = setInterval(() => {

                    const decodedJwt = jwt_decode(userStore.accessToken);
                    const isExpired = decodedJwt.exp < new Date().getTime() / 1000;

                    if ((userStore && userStore !== null && userStore?.accessToken === undefined) || isExpired) {
                        setIsInvalidAccessToken(true);
                        clearInterval(interval);
                    }

                }, 5000);
            }

        } else if (isInvalidAccessToken) {

            localStorage.removeItem("user");
            setUser(null);
            clearInterval(interval);

        }

    }, [setUser, userStore, isInvalidAccessToken]);

    if (user === null || user?.accessToken === undefined) {

        localStorage.removeItem("user");
        return <Redirect to='/auth' />

    } else {

        return children;

    }
}