import React from "react";

import {
    Box,
    Flex,
    Grid,
    SimpleGrid,
} from "@chakra-ui/react";

import LaudoCard from "components/card/LaudoCard";

export default function ListaConteudos(props) {

    const { data } = props;

    return (
        <Box pt={{ base: "180px", md: "40px", xl: "40px" }}>
            <Grid
                mb='20px'
                gridTemplateColumns={{ xl: "repeat(1, 1fr)", "2xl": "1fr 0.46fr" }}
                gap={{ base: "20px", xl: "20px" }}
                display={{ base: "block", xl: "grid" }}>
                <Flex
                    flexDirection='column'
                    gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
                    <Flex direction='column'>
                        <SimpleGrid
                            columns={{ base: 1, md: 4 }}
                            gap='20px'
                            mb={{ base: "20px", xl: "0px" }}>
                            {
                                data.map((laudo, index) => (
                                    <LaudoCard
                                        key={index}
                                        {...laudo}
                                    />
                                ))
                            }
                        </SimpleGrid>
                    </Flex>
                </Flex>
            </Grid>
        </Box>
    );
};