import React from "react";

import { Box, FormLabel, Input, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import CreatableSelect from 'react-select/creatable';
import Option from "./ComboBox";

export default function PeritoInfos(props) {

    const { formikProps, isLoading, peritosOptions, handleCreateOption, handleDeleteOption } = props;

    const textColor = useColorModeValue("secondaryGray.900", "secondaryGray.900");
    const selectSecondaryColor = useColorModeValue("secondaryGray.100", "secondaryGray.100");

    const selectStyle = {
        control: (baseStyles, _) => ({
            ...baseStyles,
            borderRadius: "16px",
            borderColor: "#878cbd4d",
            color: "#878cbd4d",
            height: "2.5rem"
        }),
        option: (baseStyles, _) => ({
            ...baseStyles,
            color: "#333333",
        }),
    }

    return (
        <SimpleGrid columns='2' gap='20px'>
            <Box>
                <FormLabel
                    as='legend'
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Perito nomeado<Text color='orange'>*</Text>
                </FormLabel>
                <CreatableSelect
                    components={{ Option }}
                    isRequired
                    isClearable
                    isDisabled={isLoading.peritos}
                    isLoading={isLoading.peritos}
                    styles={selectStyle}
                    name="perito"
                    placeholder='Selecione...'
                    formatCreateLabel={userInput => `Adicionar ${userInput}`}
                    options={peritosOptions}
                    type="perito"
                    onCreateOption={(value) => {
                        handleCreateOption(value, "perito")
                    }}
                    onDeleteOption={handleDeleteOption}
                    onChange={(e) => {
                        formikProps.values.perito.name = e !== null ? e.name : '';
                        formikProps.values.perito._id = e !== null ? e._id : '';
                    }}
                />
            </Box>
            <Box>
                <FormLabel
                    as='legend'
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                    Data entrega<Text color='orange'>*</Text>
                </FormLabel>
                <Input
                    isRequired
                    variant='auth'
                    placeholder="Selecione a data"
                    size="md"
                    name="dataEntrega"
                    type="date"
                    onChange={(e) => {
                        formikProps.values.dataEntrega = e.target.value;
                    }}
                />
            </Box>
        </SimpleGrid>
    )
};